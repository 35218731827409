import { createApp } from '@backstage/app-defaults'
import { FlatRoutes } from '@backstage/core-app-api'
import { AlertDisplay, OAuthRequestDialog } from '@backstage/core-components'
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs'
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog'
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha'
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph'
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import'
import { orgPlugin } from '@backstage/plugin-org'
import { PermissionedRoute } from '@backstage/plugin-permission-react'
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder'
import { SearchPage } from '@backstage/plugin-search'
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs'
import { UserSettingsPage } from '@backstage/plugin-user-settings'
import { HomepageCompositionRoot } from '@backstage/plugin-home'
import { shortcutsPlugin } from '@backstage-community/plugin-shortcuts'
import {
  EphemeralsPage,
  EphemeralPage,
  // CreateEphemeralPage,
} from '@internal/plugin-ephemerals'
import React from 'react'
import { Route } from 'react-router'
import './app.css'
import { apis } from './apis'
import { SignIn } from './components'
import { entityPage } from './components/catalog/EntityPage'
import { Root } from './components/Root'
import { searchPage } from './components/search/SearchPage'
import { HomePage } from './components/home/HomePage'

const app = createApp({
  apis,
  components: {
    SignInPage: SignIn,
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    })
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    })
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
    })
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    })
  },
  plugins: [shortcutsPlugin],
})

const AppProvider = app.getProvider()
const AppRouter = app.getRouter()

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    />
    <Route path="/create" element={<ScaffolderPage />} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <PermissionedRoute
      path="/catalog-import"
      permission={catalogEntityCreatePermission}
      element={<CatalogImportPage />}
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    {/* TODO - reenable when "create" feature completed - <Route path="/ephemerals/create" element={<CreateEphemeralPage />} /> */}
    <Route path="/ephemerals/:ephemeralId" element={<EphemeralPage />} />
    <Route path="/ephemerals" element={<EphemeralsPage />} />
  </FlatRoutes>
)

const App = () => (
  <AppProvider>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </AppProvider>
)

export default App
